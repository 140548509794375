import React, { useEffect, useState } from 'react';
import Table from '../parents/Table';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import config from '../../../config/env'; // Import the config
import useCachedToken from '../../../useCachedToken'; // Import the custom hook for token
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const ControlCenterIPsTable = () => {
  // Use the custom hook to get the cached token
  const token = useCachedToken();

  // API URL: Fetch all IPs
  const url = `${DJANGO_HOST}user_relay_server/`;

  const headers = [
    { name: "IPS", available: true },
    { name: "Provider", available: true },
    { name: "Projects", available: true },
    { name: "Domains", available: true },
    { name: "Mailboxes", available: true },
    { name: "Sent Emails", available: true }
  ];

  // State variables for managing IP data and pagination
  const [allIPs, setAllIPs] = useState([]); // Store all fetched IPs
  const [ips, setIPs] = useState([]); // IPs to display on the current page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 4; // Fixed at 4 items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch IPs from the API
  const fetchIPs = async () => {
    setIsLoading(true); // Start loading
    setError(null); // Reset any previous errors
    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}` // Include auth token
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Log the relay_server_name to verify order (optional)
      console.log("Fetched IPs:", data.map(ip => ({ name: ip.relay_server_name })));

      setAllIPs(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching IPs:', error);
      setError('Failed to load IPs. Please try again later.');
      setAllIPs([]); // Reset to empty array on error
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Fetch IPs when component mounts or when token changes
  useEffect(() => {
    if (token) {
      fetchIPs();
    }
  }, [url, token]);

  // Calculate totalPages whenever allIPs changes
  useEffect(() => {
    const total = Math.ceil(allIPs.length / itemsPerPage);
    setTotalPages(total || 1); // Ensure at least 1 page
    setCurrentPage(1); // Reset to first page when data changes
  }, [allIPs, itemsPerPage]);

  // Update ips to display on the current page
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setIPs(allIPs.slice(indexOfFirstItem, indexOfLastItem));
  }, [allIPs, currentPage, itemsPerPage]);

  // Pagination Helper Function to generate page numbers with ellipses
  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= 3) {
      // If there are 3 or fewer pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Determine when to show ellipses and which page numbers to display
      if (currentPage > 2) {
        pages.push('left-ellipsis');
      }

      // Always show currentPage and its immediate neighbors
      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 1) {
        pages.push('right-ellipsis');
      }
    }

    return pages;
  };

  // Handle page change when a page number is clicked
  const handlePageChange = (pageNumber) => {
    if (pageNumber === 'left-ellipsis') {
      setCurrentPage(1); // Jump to first page
      return;
    }
    if (pageNumber === 'right-ellipsis') {
      setCurrentPage(totalPages); // Jump to last page
      return;
    }
    setCurrentPage(pageNumber);
  };

  // Custom renderers for specific columns
  const renderIP = (cell, row) => {
    const id = row.relay_server_id;
    return (
      <td className="py-3 px-6">
        <Link to={`/navigate/ip/${id}/`}>{cell}</Link>
      </td>
    );
  };

  // Style Objects for consistent styling
  const styles = {
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center', // Center the pagination controls
      alignItems: 'center',
      marginTop: '20px',
      gap: '5px',
    },
    paginationButton: (isActive, isDisabled) => ({
      padding: '6px 12px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: isActive ? '#17a2b8' : 'white',
      color: isActive ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      opacity: isDisabled ? 0.6 : 1,
    }),
    paginationEllipsis: {
      padding: '6px 12px',
      cursor: 'default',
      color: '#555',
    },
    seeMoreLink: {
      textDecoration: 'none',
      color: '#17a2b8',
      fontWeight: 'bold',
      padding: '10px',
    },
    errorText: {
      color: 'red',
      textAlign: 'center',
      marginTop: '10px',
    },
    noResultsText: {
      textAlign: 'center',
      marginTop: '20px',
      color: '#555',
    },
  };

  return (
    <div>
      {/* Display loading spinner */}
      {isLoading && <Spinner />}

      {/* Display error message if any */}
      {error && <div style={styles.errorText}>{error}</div>}

      {/* Display "No results found" if not loading, no error, and no IPs */}
      {!isLoading && !error && allIPs.length === 0 && (
        <div style={styles.noResultsText}>No IPs found.</div>
      )}

      {/* Table and Pagination Controls */}
      {!isLoading && !error && allIPs.length > 0 && (
        <>
          {/* Table Component */}
          <Table
            data={ips} // Pass the current page's IPs
            title={"IPs"}
            headers={headers}
            preffixColumn={false}
            suffixColumn={false}
            renderPrefix={null}
            renderSuffix={null}
            customRenderers={{ "relay_server_name": renderIP }}
            excludeFields={['relay_server_id', 'status', 'health', 'open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}
          />

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div style={styles.paginationContainer}>
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={styles.paginationButton(false, currentPage === 1)}
                aria-label="Previous Page"
              >
                &laquo;
              </button>

              {/* Page Numbers and Ellipses */}
              {getPageNumbers().map((item, index) => {
                if (item === 'left-ellipsis') {
                  return (
                    <span key={index} style={styles.paginationEllipsis}>
                      &hellip;
                    </span>
                  );
                }

                if (item === 'right-ellipsis') {
                  return (
                    <span key={index} style={styles.paginationEllipsis}>
                      &hellip;
                    </span>
                  );
                }

                return (
                  <button
                    key={index}
                    onClick={() => handlePageChange(item)}
                    style={styles.paginationButton(currentPage === item, false)}
                    aria-current={currentPage === item ? 'page' : undefined}
                    aria-label={`Page ${item}`}
                  >
                    {item}
                  </button>
                );
              })}

              {/* Next Button */}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={styles.paginationButton(false, currentPage === totalPages)}
                aria-label="Next Page"
              >
                &raquo;
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ControlCenterIPsTable;
