import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Input from "../parents/Input.js";
import Dropdown from "../parents/Dropdown.js";
import useCachedToken from '../../../useCachedToken'; // Import the custom hook
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    Select: {
        width: '100%',
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        boxSizing: 'border-box',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '10px',
    },
};

const DJANGO_HOST = config.djangoHost;

const ProjectsModalAdd = ({ reload }) => {
    const [name, setName] = useState("");
    const [selectedIp, setSelectedIp] = useState('');
    const [ips, setIps] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = useCachedToken(); // Use the custom hook to get the token
    const { getToken } = useAuth();

    // Fetch IPs when the component mounts or token changes
    useEffect(() => {
        const fetchData = async () => {
            if (!token) return; // Exit if token is not available yet
            setIsLoading(true);
            setErrors([]);
            try {
                const response = await fetch(`${DJANGO_HOST}user_relay_server/`, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setIps(Array.isArray(data) ? data.reverse() : []); // Reverse to ensure the latest IP is first

                if (data.length > 0) {
                    setSelectedIp(data[0].relay_server_id);
                } else {
                    setSelectedIp('');
                }
            } catch (error) {
                console.error('Error fetching IPs:', error);
                setErrors(['Failed to fetch IPs. Please try again later.']);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [token, DJANGO_HOST]);

    const handleIPChange = (event) => {
        const selectedId = event.target.value;
        setSelectedIp(selectedId);
    };

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Add a Project</label>
        );
    };

    const renderBody = () => {
        // If loading, display the spinner
        if (isLoading) {
            return (
                <div style={defaultStyles.SpinnerContainer}>
                    <Spinner />
                </div>
            );
        }

        const ipOptions = ips.map(ip => ({ value: ip.relay_server_id, label: ip.relay_server_name }));

        return (
            <>
                <p className="text-justify">
                    A project is a folder where you can group domains. You may use it to split teams, clients, or objectives.
                </p>
                <br />
                <label className="font-semibold">IP</label>
                <div className="pb-4 pt-2">
                    <select
                        value={selectedIp}
                        onChange={handleIPChange}
                        style={defaultStyles.Select}
                        disabled={isLoading} // Disable select during loading
                    >
                        {ipOptions.length > 0 ? (
                            ipOptions.map((ip) => (
                                <option key={ip.value} value={ip.value}>
                                    {ip.label}
                                </option>
                            ))
                        ) : (
                            <option value="" disabled>
                                No IPs available
                            </option>
                        )}
                    </select>
                </div>
                <label className="font-semibold">Project Name</label>
                <div className="pb-4 pt-2">
                    <Input
                        name="name"
                        placeholder="Project Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={isLoading} // Disable input during loading
                    />
                </div>
            </>
        );
    };

    const renderOptions = (closeModal) => {
        const action = async () => {
            const newErrors = [];

            // Input validation
            if (!selectedIp) {
                newErrors.push('Please choose an IP.');
            }

            if (!name.trim()) {
                newErrors.push('Please enter a project name.');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            // Clear previous errors and start loading
            setErrors([]);
            setIsLoading(true);

            const url = `${DJANGO_HOST}projects/`;

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'name': name.trim(),
                        'relay_server': selectedIp,
                    }),
                });

                if (response.status === 201) {
                    reload();
                    closeModal();
                } else if (response.status === 400) {
                    const data = await response.json();
                    const serverErrors = [];
                    for (const key in data) {
                        if (Array.isArray(data[key])) {
                            data[key].forEach(err => serverErrors.push(`${key}: ${err}`));
                        } else {
                            serverErrors.push(`${key}: ${data[key]}`);
                        }
                    }
                    setErrors(serverErrors);
                } else {
                    const errorText = await response.text();
                    setErrors(['An unexpected error occurred. Please try again later.']);
                    console.error('Unexpected response:', response.status, errorText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(['Failed to add the project. Please check your network and try again.']);
            } finally {
                setIsLoading(false);
            }
        };

        return (
            <>
                <Button
                    action={closeModal}
                    style={defaultStyles.CancelButton}
                    label="Cancel"
                    disabled={isLoading} // Disable during loading
                />
                <Button
                    action={action}
                    style={isLoading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton}
                    label={isLoading ? "Processing..." : "Add Project"}
                    disabled={isLoading}
                />
            </>
        );
    };

    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul className="mb-4">
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorText}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            text={"Add Project"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default ProjectsModalAdd;
