import React, { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import config from '../../../config/env';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// List of countries with Chargebee country codes
const countryCodes = [
    { code: "AF", name: "Afghanistan" },
    { code: "AX", name: "Aland Islands" },
    { code: "AL", name: "Albania" },
    { code: "DZ", name: "Algeria" },
    { code: "AS", name: "American Samoa" },
    { code: "AD", name: "Andorra" },
    { code: "AO", name: "Angola" },
    { code: "AI", name: "Anguilla" },
    { code: "AQ", name: "Antarctica" },
    { code: "AG", name: "Antigua and Barbuda" },
    { code: "AR", name: "Argentina" },
    { code: "AM", name: "Armenia" },
    { code: "AW", name: "Aruba" },
    { code: "AU", name: "Australia" },
    { code: "AT", name: "Austria" },
    { code: "AZ", name: "Azerbaijan" },
    { code: "BS", name: "Bahamas" },
    { code: "BH", name: "Bahrain" },
    { code: "BD", name: "Bangladesh" },
    { code: "BB", name: "Barbados" },
    { code: "BY", name: "Belarus" },
    { code: "BE", name: "Belgium" },
    { code: "BZ", name: "Belize" },
    { code: "BJ", name: "Benin" },
    { code: "BM", name: "Bermuda" },
    { code: "BT", name: "Bhutan" },
    { code: "BO", name: "Bolivia" },
    { code: "BA", name: "Bosnia and Herzegovina" },
    { code: "BW", name: "Botswana" },
    { code: "BV", name: "Bouvet Island" },
    { code: "BR", name: "Brazil" },
    { code: "IO", name: "British Indian Ocean Territory" },
    { code: "BN", name: "Brunei Darussalam" },
    { code: "BG", name: "Bulgaria" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BI", name: "Burundi" },
    { code: "KH", name: "Cambodia" },
    { code: "CM", name: "Cameroon" },
    { code: "CA", name: "Canada" },
    { code: "CV", name: "Cape Verde" },
    { code: "KY", name: "Cayman Islands" },
    { code: "CF", name: "Central African Republic" },
    { code: "TD", name: "Chad" },
    { code: "CL", name: "Chile" },
    { code: "CN", name: "China" },
    { code: "CX", name: "Christmas Island" },
    { code: "CC", name: "Cocos (Keeling) Islands" },
    { code: "CO", name: "Colombia" },
    { code: "KM", name: "Comoros" },
    { code: "CG", name: "Congo" },
    { code: "CD", name: "Congo, The Democratic Republic of the" },
    { code: "CK", name: "Cook Islands" },
    { code: "CR", name: "Costa Rica" },
    { code: "CI", name: "Cote D'Ivoire" },
    { code: "HR", name: "Croatia" },
    { code: "CU", name: "Cuba" },
    { code: "CY", name: "Cyprus" },
    { code: "CZ", name: "Czech Republic" },
    { code: "DK", name: "Denmark" },
    { code: "DJ", name: "Djibouti" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominican Republic" },
    { code: "EC", name: "Ecuador" },
    { code: "EG", name: "Egypt" },
    { code: "SV", name: "El Salvador" },
    { code: "GQ", name: "Equatorial Guinea" },
    { code: "ER", name: "Eritrea" },
    { code: "EE", name: "Estonia" },
    { code: "ET", name: "Ethiopia" },
    { code: "FK", name: "Falkland Islands (Malvinas)" },
    { code: "FO", name: "Faroe Islands" },
    { code: "FJ", name: "Fiji" },
    { code: "FI", name: "Finland" },
    { code: "FR", name: "France" },
    { code: "GF", name: "French Guiana" },
    { code: "PF", name: "French Polynesia" },
    { code: "TF", name: "French Southern Territories" },
    { code: "GA", name: "Gabon" },
    { code: "GM", name: "Gambia" },
    { code: "GE", name: "Georgia" },
    { code: "DE", name: "Germany" },
    { code: "GH", name: "Ghana" },
    { code: "GI", name: "Gibraltar" },
    { code: "GR", name: "Greece" },
    { code: "GL", name: "Greenland" },
    { code: "GD", name: "Grenada" },
    { code: "GP", name: "Guadeloupe" },
    { code: "GU", name: "Guam" },
    { code: "GT", name: "Guatemala" },
    { code: "GG", name: "Guernsey" },
    { code: "GN", name: "Guinea" },
    { code: "GW", name: "Guinea-Bissau" },
    { code: "GY", name: "Guyana" },
    { code: "HT", name: "Haiti" },
    { code: "HM", name: "Heard Island and Mcdonald Islands" },
    { code: "VA", name: "Holy See (Vatican City State)" },
    { code: "HN", name: "Honduras" },
    { code: "HK", name: "Hong Kong" },
    { code: "HU", name: "Hungary" },
    { code: "IS", name: "Iceland" },
    { code: "IN", name: "India" },
    { code: "ID", name: "Indonesia" },
    { code: "IR", name: "Iran, Islamic Republic Of" },
    { code: "IQ", name: "Iraq" },
    { code: "IE", name: "Ireland" },
    { code: "IM", name: "Isle of Man" },
    { code: "IL", name: "Israel" },
    { code: "IT", name: "Italy" },
    { code: "JM", name: "Jamaica" },
    { code: "JP", name: "Japan" },
    { code: "JE", name: "Jersey" },
    { code: "JO", name: "Jordan" },
    { code: "KZ", name: "Kazakhstan" },
    { code: "KE", name: "Kenya" },
    { code: "KI", name: "Kiribati" },
    { code: "KP", name: "Korea, Democratic People's Republic of" },
    { code: "KR", name: "Korea, Republic of" },
    { code: "KW", name: "Kuwait" },
    { code: "KG", name: "Kyrgyzstan" },
    { code: "LA", name: "Lao People's Democratic Republic" },
    { code: "LV", name: "Latvia" },
    { code: "LB", name: "Lebanon" },
    { code: "LS", name: "Lesotho" },
    { code: "LR", name: "Liberia" },
    { code: "LY", name: "Libyan Arab Jamahiriya" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LT", name: "Lithuania" },
    { code: "LU", name: "Luxembourg" },
    { code: "MO", name: "Macao" },
    { code: "MK", name: "Macedonia, The Former Yugoslav Republic of" },
    { code: "MG", name: "Madagascar" },
    { code: "MW", name: "Malawi" },
    { code: "MY", name: "Malaysia" },
    { code: "MV", name: "Maldives" },
    { code: "ML", name: "Mali" },
    { code: "MT", name: "Malta" },
    { code: "MH", name: "Marshall Islands" },
    { code: "MQ", name: "Martinique" },
    { code: "MR", name: "Mauritania" },
    { code: "MU", name: "Mauritius" },
    { code: "YT", name: "Mayotte" },
    { code: "MX", name: "Mexico" },
    { code: "FM", name: "Micronesia, Federated States of" },
    { code: "MD", name: "Moldova, Republic of" },
    { code: "MC", name: "Monaco" },
    { code: "MN", name: "Mongolia" },
    { code: "ME", name: "Montenegro" },
    { code: "MS", name: "Montserrat" },
    { code: "MA", name: "Morocco" },
    { code: "MZ", name: "Mozambique" },
    { code: "MM", name: "Myanmar" },
    { code: "NA", name: "Namibia" },
    { code: "NR", name: "Nauru" },
    { code: "NP", name: "Nepal" },
    { code: "NL", name: "Netherlands" },
    { code: "AN", name: "Netherlands Antilles" },
    { code: "NC", name: "New Caledonia" },
    { code: "NZ", name: "New Zealand" },
    { code: "NI", name: "Nicaragua" },
    { code: "NE", name: "Niger" },
    { code: "NG", name: "Nigeria" },
    { code: "NU", name: "Niue" },
    { code: "NF", name: "Norfolk Island" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "NO", name: "Norway" },
    { code: "OM", name: "Oman" },
    { code: "PK", name: "Pakistan" },
    { code: "PW", name: "Palau" },
    { code: "PS", name: "Palestinian Territory, Occupied" },
    { code: "PA", name: "Panama" },
    { code: "PG", name: "Papua New Guinea" },
    { code: "PY", name: "Paraguay" },
    { code: "PE", name: "Peru" },
    { code: "PH", name: "Philippines" },
    { code: "PN", name: "Pitcairn" },
    { code: "PL", name: "Poland" },
    { code: "PT", name: "Portugal" },
    { code: "PR", name: "Puerto Rico" },
    { code: "QA", name: "Qatar" },
    { code: "RE", name: "Reunion" },
    { code: "RO", name: "Romania" },
    { code: "RU", name: "Russian Federation" },
    { code: "RW", name: "Rwanda" },
    { code: "BL", name: "Saint Barthelemy" },
    { code: "SH", name: "Saint Helena" },
    { code: "KN", name: "Saint Kitts and Nevis" },
    { code: "LC", name: "Saint Lucia" },
    { code: "MF", name: "Saint Martin" },
    { code: "PM", name: "Saint Pierre and Miquelon" },
    { code: "VC", name: "Saint Vincent and the Grenadines" },
    { code: "WS", name: "Samoa" },
    { code: "SM", name: "San Marino" },
    { code: "ST", name: "Sao Tome and Principe" },
    { code: "SA", name: "Saudi Arabia" },
    { code: "SN", name: "Senegal" },
    { code: "RS", name: "Serbia" },
    { code: "SC", name: "Seychelles" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SG", name: "Singapore" },
    { code: "SK", name: "Slovakia" },
    { code: "SI", name: "Slovenia" },
    { code: "SB", name: "Solomon Islands" },
    { code: "SO", name: "Somalia" },
    { code: "ZA", name: "South Africa" },
    { code: "GS", name: "South Georgia and the South Sandwich Islands" },
    { code: "ES", name: "Spain" },
    { code: "LK", name: "Sri Lanka" },
    { code: "SD", name: "Sudan" },
    { code: "SR", name: "Suriname" },
    { code: "SJ", name: "Svalbard and Jan Mayen" },
    { code: "SZ", name: "Swaziland" },
    { code: "SE", name: "Sweden" },
    { code: "CH", name: "Switzerland" },
    { code: "SY", name: "Syrian Arab Republic" },
    { code: "TW", name: "Taiwan, Province of China" },
    { code: "TJ", name: "Tajikistan" },
    { code: "TZ", name: "Tanzania, United Republic of" },
    { code: "TH", name: "Thailand" },
    { code: "TL", name: "Timor-Leste" },
    { code: "TG", name: "Togo" },
    { code: "TK", name: "Tokelau" },
    { code: "TO", name: "Tonga" },
    { code: "TT", name: "Trinidad and Tobago" },
    { code: "TN", name: "Tunisia" },
    { code: "TR", name: "Turkey" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TC", name: "Turks and Caicos Islands" },
    { code: "TV", name: "Tuvalu" },
    { code: "UG", name: "Uganda" },
    { code: "UA", name: "Ukraine" },
    { code: "AE", name: "United Arab Emirates" },
    { code: "GB", name: "United Kingdom" },
    { code: "US", name: "United States" },
    { code: "UM", name: "United States Minor Outlying Islands" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Uzbekistan" },
    { code: "VU", name: "Vanuatu" },
    { code: "VE", name: "Venezuela" },
    { code: "VN", name: "Viet Nam" },
    { code: "VG", name: "Virgin Islands, British" },
    { code: "VI", name: "Virgin Islands, U.S." },
    { code: "WF", name: "Wallis and Futuna" },
    { code: "EH", name: "Western Sahara" },
    { code: "YE", name: "Yemen" },
    { code: "ZM", name: "Zambia" },
    { code: "ZW", name: "Zimbabwe" }
];

const Billing = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [mailboxCount, setMailboxCount] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [availablePlans, setAvailablePlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [isCancelDropdownOpen, setIsCancelDropdownOpen] = useState(false);
    const [showBillingForm, setShowBillingForm] = useState(false);
    const [newBillingAddress, setNewBillingAddress] = useState({});
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [billingAddress, setBillingAddress] = useState({});
    const [paymentMethod, setPaymentMethod] = useState({});

    // State variables to toggle visibility
    const [showBillingDetails, setShowBillingDetails] = useState(false);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);

    const DJANGO_HOST = config.djangoHost;
    const { getToken } = useAuth();

    useEffect(() => {
        async function fetchUserInfo() {
          try {
            const token = await getToken();
            const response = await axios.get(`${DJANGO_HOST}api/userinfo/`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            
            setUserInfo(response.data);
            await fetchSubscriptionDetails(response.data.email);
            await fetchAvailablePlans(response.data.email);
          } catch (error) {
            setError('Error fetching user info');
            console.error('Error fetching user info:', error);
          } finally {
            setLoading(false);
          }
        }
    
        fetchUserInfo();
      }, []);

    const fetchAvailablePlans = async (email, userSubscriptions) => {
        try {
            const response = await axios.get(DJANGO_HOST + 'get_available_plans/', {
                params: { email: email }
            });

            const availablePlans = response.data.available_plans || [];
            const subscribedPlanNames = userSubscriptions.map(sub => sub.plan_name);

            const filteredPlans = availablePlans.filter(plan => !subscribedPlanNames.includes(plan.id));

            setAvailablePlans(filteredPlans);
            console.log("Filtered available plans:", filteredPlans);
        } catch (error) {
            console.error('Error fetching available plans:', error);
        }
    };

    const fetchSubscriptionDetails = async (email) => {
        try {
            const response = await axios.get(DJANGO_HOST + 'get_user_subscriptions/', {
                params: { email: email }
            });

            if (response.data.subscriptions.length > 0) {
                const subscriptionDetails = response.data.subscriptions.map(subscription => ({
                    ...subscription,
                    billing_cycle: subscription.billing_cycle || 'Monthly',
                    total_mailboxes: subscription.plan_name === 'Base-Subscription-USD-Monthly' ? subscription.total_mailboxes : undefined,
                    customer_id: subscription.customer_id
                }));
                console.log('Subscription Details:', subscriptionDetails);
                setSubscriptionDetails(subscriptionDetails);
                setMailboxCount(subscriptionDetails.find(subscription => subscription.plan_name === 'Base-Subscription-USD-Monthly')?.total_mailboxes || 0);
                setNewBillingAddress(subscriptionDetails[0].billing_address || {});
                setBillingAddress(subscriptionDetails[0].billing_address || {});
                setPaymentMethod(subscriptionDetails[0].payment_method || {});
                fetchAvailablePlans(email, subscriptionDetails);
            } else {
                setSubscriptionDetails([]);
                fetchAvailablePlans(email, []);
            }
        } catch (error) {
            setError('Error fetching subscription details');
            console.error('Error fetching subscription details:', error);
        } finally {
            setLoading(false);
        }
    };


    const cancelSubscription = async (customer_id, subscription_id) => {
        if (!subscriptionDetails) {
            alert('Subscription details not loaded yet.');
            return;
        }

        const confirmCancel = window.confirm("Are you sure you want to cancel your subscription?");
        if (!confirmCancel) {
            return;
        }

        try {
            const csrfToken = getCookie('csrftoken');
            const response = await axios.post(DJANGO_HOST + 'cancel_subscription/', {
                customer_id: customer_id,
                subscription_id: subscription_id,
                cancel_option: 'end_of_term' // Changed to cancel at the end of term
            }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.message) {
                alert('Subscription canceled successfully');
                fetchSubscriptionDetails(userInfo.email);
            } else {
                alert('Failed to cancel subscription');
            }
        } catch (error) {
            console.error('Error canceling subscription:', error);
            alert('Error canceling subscription');
        }
    };

    const renewSubscription = async (customer_id) => {
        if (!subscriptionDetails) {
            alert('Subscription details not loaded yet.');
            return;
        }

        try {
            const csrfToken = getCookie('csrftoken');
            const response = await axios.post(DJANGO_HOST + 'renew_subscription/', {
                customer_id: customer_id
            }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.message) {
                alert('Subscription renewed successfully');
                fetchSubscriptionDetails(userInfo.email);
            } else {
                alert('Failed to renew subscription');
            }
        } catch (error) {
            console.error('Error renewing subscription:', error);
            alert('Error renewing subscription');
        }
    };

    const handleBillingAddressChange = (e) => {
        setNewBillingAddress({
            ...newBillingAddress,
            [e.target.name]: e.target.value
        });
    };

    const updateBillingAddress = async () => {
        if (!subscriptionDetails) {
            alert('Subscription details not loaded yet.');
            return;
        }

        const zipCodePattern = /^\d{5}(-\d{4})?$/;
        if (!zipCodePattern.test(newBillingAddress.zip)) {
            alert('Invalid zip code format. Please enter a valid zip code.');
            return;
        }

        try {
            const csrfToken = getCookie('csrftoken');
            const response = await axios.post(DJANGO_HOST + 'update_billing_address/', {
                customer_id: subscriptionDetails[0].customer_id,
                billing_address: newBillingAddress
            }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.message) {
                alert('Billing address updated successfully');
                fetchSubscriptionDetails(userInfo.email);
                setShowBillingForm(false);
            } else {
                alert('Failed to update billing address');
            }
        } catch (error) {
            console.error('Error updating billing address:', error);
            alert('Error updating billing address');
        }
    };

    const updatePaymentMethod = async () => {
        if (!subscriptionDetails) {
            alert('Subscription details not loaded yet.');
            return;
        }

        try {
            const response = await axios.get(DJANGO_HOST + 'create_update_payment_method_page/', {
                params: {
                    customer_id: subscriptionDetails[0].customer_id
                }
            });

            if (response.data.url) {
                window.location.href = response.data.url;
            } else {
                alert('Failed to create update payment method page');
            }
        } catch (error) {
            console.error('Error creating update payment method page:', error);
            alert('Error creating update payment method page');
        }
    };

    // Add the "End Trial" button and handle its logic
    const handleEndTrial = async () => {
        if (!userInfo || !subscriptionDetails) {
            alert("User or subscription details not loaded.");
            return;
        }
    
        const baseSubscription = subscriptionDetails.find(
            (subscription) => subscription.plan_name === "Base-Subscription-USD-Monthly"
        );
    
        if (!baseSubscription) {
            alert("Base Subscription not found.");
            return;
        }
    
        try {
            // Check if the user has a payment method
            const response = await axios.get(DJANGO_HOST + 'get_payment_methods/', {
                params: {
                    customer_id: subscriptionDetails[0].customer_id
                }
            });
    
            if (response.data.payment_methods.length > 0) {
                // If the user has a valid payment method, cancel trial and renew subscription
                await cancelAndRenewSubscription(baseSubscription.customer_id, baseSubscription.subscription_id);
            } else {
                // If no payment method is associated, show a prompt and then redirect
                const confirmRedirect = window.confirm(
                    "You do not have a valid payment method. You will be redirected to update your payment information."
                );
                if (confirmRedirect) {
                    const paymentMethodResponse = await axios.get(DJANGO_HOST + 'create_update_payment_method_page/', {
                        params: {
                            customer_id: subscriptionDetails[0].customer_id
                        }
                    });
                    // Redirect to the payment method update page
                    window.location.href = paymentMethodResponse.data.url;
                }
            }
        } catch (error) {
            console.error("Error handling end trial:", error);
            alert("Error handling end trial.");
        }
    };
    
    // Function to cancel and renew the subscription
    const cancelAndRenewSubscription = async (customer_id, subscription_id) => {
        try {
            await axios.post(DJANGO_HOST + 'cancel_subscription/', {
                customer_id,
                subscription_id,
                cancel_option: "immediately"
            });
            // Once canceled, renew the subscription
            await axios.post(DJANGO_HOST + 'renew_subscription/', {
                customer_id
            });
            alert("Trial ended and subscription activated successfully.");
            window.location.reload(); // Refresh the page to reflect changes
        } catch (error) {
            console.error("Error cancelling and renewing subscription:", error);
            alert("Error ending trial.");
        }
    };
    
    return (
        <div className="p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-6">Billing</h1>
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div className="text-red-500">{error}</div>
            ) : (
                <div className="space-y-6">
                    <h2 className="text-xl font-semibold">Subscription Details</h2>
                    {subscriptionDetails
                        .filter(subscription => subscription.plan_name === 'Base-Subscription-USD-Monthly')
                        .map((subscription, index) => (
                            <div className="space-y-4 border-b pb-4 mb-4">
                                <div className="flex justify-between items-center">
                                    <p>
                                        <strong>Subscription Status:</strong> {subscription.status}
                                    </p>
                                    {subscription.status === "in_trial" && (
                                        <button
                                            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                                            onClick={handleEndTrial}
                                        >
                                            Upgrade Account
                                        </button>
                                    )}
                                </div>
                                <p>
                                    <strong>Billing Cycle:</strong> {subscription.billing_cycle}
                                </p>
                                <p>
                                    <strong>Next Billing Date:</strong> {subscription.next_billing_date
                                        ? new Date(subscription.next_billing_date * 1000).toLocaleDateString()
                                        : 'None'}
                                </p>
                                <p>
                                    <strong>Amount Due:</strong> ${subscription.amount_due / 100}
                                </p>
                                {subscription.plan_name === 'Base-Subscription-USD-Monthly' && (
                                    <p>
                                        <strong>Total Mailboxes:</strong> {subscription.total_mailboxes}
                                    </p>
                                )}
    
                                
    
                                {showBillingDetails && (
                                    <div className="mt-4 space-y-4 bg-gray-100 p-4 rounded-md">
                                        <h3 className="text-lg font-semibold">Billing Address</h3>
                                        {billingAddress && (
                                            <div className="space-y-1">
                                                <p><strong>First Name:</strong> {billingAddress.first_name}</p>
                                                <p><strong>Last Name:</strong> {billingAddress.last_name}</p>
                                                <p><strong>Line 1:</strong> {billingAddress.line1}</p>
                                                <p><strong>Line 2:</strong> {billingAddress.line2}</p>
                                                <p><strong>City:</strong> {billingAddress.city}</p>
                                                <p><strong>State:</strong> {billingAddress.state}</p>
                                                <p><strong>Country:</strong> {billingAddress.country}</p>
                                                <p><strong>Zip Code:</strong> {billingAddress.zip}</p>
                                            </div>
                                        )}
    
                                        {/* Update Billing Address Form */}
                                        <button
                                            className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                                            onClick={() => setShowBillingForm(!showBillingForm)}
                                        >
                                            {showBillingForm ? 'Hide Update Billing Address' : 'Update Billing Address'}
                                        </button>
                                        {showBillingForm && (
                                            <div className="mt-4 space-y-3">
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First Name</label>
                                                        <input
                                                            type="text"
                                                            id="first_name"
                                                            name="first_name"
                                                            value={newBillingAddress.first_name || ''}
                                                            onChange={handleBillingAddressChange}
                                                            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">Last Name</label>
                                                        <input
                                                            type="text"
                                                            id="last_name"
                                                            name="last_name"
                                                            value={newBillingAddress.last_name || ''}
                                                            onChange={handleBillingAddressChange}
                                                            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="line1" className="block text-sm font-medium text-gray-700">Address Line 1</label>
                                                    <input
                                                        type="text"
                                                        id="line1"
                                                        name="line1"
                                                        value={newBillingAddress.line1 || ''}
                                                        onChange={handleBillingAddressChange}
                                                        className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="line2" className="block text-sm font-medium text-gray-700">Address Line 2</label>
                                                    <input
                                                        type="text"
                                                        id="line2"
                                                        name="line2"
                                                        value={newBillingAddress.line2 || ''}
                                                        onChange={handleBillingAddressChange}
                                                        className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                    />
                                                </div>
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
                                                        <input
                                                            type="text"
                                                            id="city"
                                                            name="city"
                                                            value={newBillingAddress.city || ''}
                                                            onChange={handleBillingAddressChange}
                                                            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">State</label>
                                                        <input
                                                            type="text"
                                                            id="state"
                                                            name="state"
                                                            value={newBillingAddress.state || ''}
                                                            onChange={handleBillingAddressChange}
                                                            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label htmlFor="zip" className="block text-sm font-medium text-gray-700">Zip Code</label>
                                                        <input
                                                            type="text"
                                                            id="zip"
                                                            name="zip"
                                                            value={newBillingAddress.zip || ''}
                                                            onChange={handleBillingAddressChange}
                                                            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                                <button
                                                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                                                    onClick={updateBillingAddress}
                                                >
                                                    Save Address
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
    
                                {/* Payment Details Toggle */}
                                <button
                                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-2"
                                    onClick={() => setShowPaymentDetails(!showPaymentDetails)}
                                >
                                    {showPaymentDetails ? 'Hide Payment Details' : 'Show Payment Details'}
                                </button>
                                {showPaymentDetails && (
                                    <div className="mt-4 space-y-2 bg-gray-100 p-4 rounded-md">
                                        <h3 className="text-lg font-semibold">Payment Method</h3>
                                        {paymentMethod && (
                                            <div>
                                                <p><strong>Card Last 4 Digits:</strong> {paymentMethod.last4}</p>
                                                <p><strong>Expiry Month:</strong> {paymentMethod.expiry_month}</p>
                                                <p><strong>Expiry Year:</strong> {paymentMethod.expiry_year}</p>
                                            </div>
                                        )}
    
                                        <button
                                            className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                                            onClick={updatePaymentMethod}
                                        >
                                            Update Payment Method
                                        </button>
                                    </div>
                                )}
    
                                {/* Cancel or Renew Subscription */}
                                {(subscription.status === "active" || subscription.status === "in_trial") ? (
                                    <div className="flex space-x-4 mt-4">
                                        <button
                                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                            onClick={async () => {
                                                const confirmCancel = window.confirm("Are you sure you want to cancel your subscription at the end of the term?");
                                                if (confirmCancel) {
                                                    await cancelSubscription('end_of_term', subscription.customer_id, subscription.subscription_id); 
                                                    window.location.reload();
                                                }
                                            }}
                                        >
                                            Cancel Subscription
                                        </button>
                                    </div>
                                ) : (
                                    (subscription.status === "non_renewing" || subscription.status === "cancelled") && (
                                        <div className="flex space-x-4 mt-4">
                                            <button
                                                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                                                onClick={() => renewSubscription(subscription.customer_id)}
                                            >
                                                Renew Subscription
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                    
                </div>
            )}
        </div>
    );    
};

export default Billing;
