import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Table from '../parents/Table.js';
import Checkbox from '../parents/Checkbox.js';
import ConnectedIpsModalEdit from './ConnectedIpsModalEdit.js';
import ConnectedIpsModalDelete from './ConnectedIpsModalDelete.js';
import useCachedToken from '../../../useCachedToken'; 
import config from '../../../config/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDown, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Spinner'; // Import the Spinner component

const ConnectedIpsIPsTable = ({ reload }) => {
    const DJANGO_HOST = config.djangoHost;
    const token = useCachedToken();

    // State for sorting
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for A-Z, 'desc' for Z-A
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility
    const dropdownRef = useRef(null); // Reference for click outside

    // Pagination States
    const [allIPs, setAllIPs] = useState([]); // Store all fetched IPs
    const [ips, setIPs] = useState([]); // IPs to display on the current page
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [itemsPerPage, setItemsPerPage] = useState(20); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state

    // Determine sortBy based on sortOrder
    const getSortParameters = (order) => {
        return { sortBy: 'relay_server__name', sortOrder: order };
    };

    const { sortBy, sortOrder: currentSortOrder } = getSortParameters(sortOrder);

    // Construct the API URL based on sort parameters
    const url = `${DJANGO_HOST}user_relay_server/?sort_by=${sortBy}&sort_order=${currentSortOrder}`;

    const headers = [
        { name: "IPS", available: true, key: "relay_server_name" }, // Ensure the key matches the row data
        { name: "Provider", available: true },
        { name: "Projects", available: true },
        { name: "Domains", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true }
        // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints, Status, and Health from the headers
    ];

    // Fetch IPs from the API
    const fetchIPs = async () => {
        setIsLoading(true); // Start loading
        setError(null); // Reset error state
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            
            // Log the relay_server_name and created_at to verify order
            console.log("Fetched Connected IPs sorted by relay_server_name:", data.map(ip => ({ name: ip.relay_server_name, created_at: ip.created_at })));

            setAllIPs(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching connected IPs:', error);
            setError('Failed to load connected IPs. Please try again later.');
            setAllIPs([]); // Reset to empty array on error
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    // Fetch IPs when component mounts or when token, sortOrder, or reload changes
    useEffect(() => {
        if (token) {
            fetchIPs();
        }
    }, [url, token, sortOrder, reload]);

    // Calculate totalPages whenever allIPs or itemsPerPage changes
    useEffect(() => {
        const total = Math.ceil(allIPs.length / itemsPerPage);
        setTotalPages(total || 1); // Ensure at least 1 page
        setCurrentPage(prevPage => Math.min(prevPage, total) || 1); // Ensure currentPage is within bounds
    }, [allIPs, itemsPerPage]);

    // Update ips to display on the current page
    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setIPs(allIPs.slice(indexOfFirstItem, indexOfLastItem));
    }, [allIPs, currentPage, itemsPerPage]);

    // Handle sort order toggle
    const handleSortOrderToggle = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        setCurrentPage(1); // Reset to first page on sort
    };

    // Handle dropdown toggle if needed in future (currently not used)
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    // Close dropdown when clicking outside (currently not used since there's no dropdown)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    // Render the IPS as a link
    const renderIP = (cell, row) => {
        const id = row.relay_server_id;
        return (
            <td className="py-3 px-6">
                <Link to={`/navigate/ip/${id}/`}>
                    {cell}
                </Link>
            </td>
        );
    };

    // Render the Actions column with Edit and Delete modals
    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex whitespace-normal break-words">
                <ConnectedIpsModalEdit name={row.relay_server_name} id={row.relay_server_id} reload={reload} />
                <ConnectedIpsModalDelete name={row.relay_server_name} id={row.relay_server_id} reload={reload} />
            </td>
        );
    };

    // Pagination Helper Function
    const getPageNumbers = () => {
        const pages = [];

        if (totalPages <= 3) {
            // If there are 3 or fewer pages, show all page numbers
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Determine when to show ellipses and which page numbers to display
            if (currentPage > 2) {
                pages.push('left-ellipsis');
            }

            // Always show currentPage and its immediate neighbors
            const startPage = Math.max(1, currentPage - 1);
            const endPage = Math.min(totalPages, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (currentPage < totalPages - 1) {
                pages.push('right-ellipsis');
            }
        }

        return pages;
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber === 'left-ellipsis') {
            setCurrentPage(1); // Jump to first page
            return;
        }
        if (pageNumber === 'right-ellipsis') {
            setCurrentPage(totalPages); // Jump to last page
            return;
        }
        setCurrentPage(pageNumber);
    };

    // Handle itemsPerPage change
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page on itemsPerPage change
    };

    // Style Objects
    const styles = {
        headerButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            position: 'relative', // To position dropdown absolutely
        },
        sortButton: {
            backgroundColor: '#17a2b8',
            color: 'white',
            padding: '8px 12px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
        },
        showLabel: {
            marginRight: '10px',
        },
        itemsPerPageSelect: {
            padding: '6px 10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            width: '150px', // Increased width to prevent overlapping
        },
        paginationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            gap: '5px',
        },
        paginationButton: (isActive, isDisabled) => ({
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: isActive ? '#17a2b8' : 'white',
            color: isActive ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.6 : 1,
        }),
        paginationEllipsis: {
            padding: '8px 12px',
        },
        errorText: {
            color: 'red',
            textAlign: 'center',
            marginTop: '10px',
        },
        noResultsText: {
            textAlign: 'center',
            marginTop: '20px',
            color: '#555',
        },
        loadingText: {
            textAlign: 'center',
            marginTop: '10px',
        },
    };

    return (
        <div>
            <div style={styles.headerButtons} ref={dropdownRef}>
                {/* Sort Button */}
                <button 
                    onClick={handleSortOrderToggle} 
                    style={styles.sortButton} 
                    aria-label={`Sort ${sortOrder === 'asc' ? 'descending' : 'ascending'}`}>
                    Sort: {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
                    <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortAlphaDown : faSortAlphaUp} />
                </button>

                {/* Items Per Page Selector */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="itemsPerPage" style={styles.showLabel}>Show:</label>
                    <select
                        id="itemsPerPage"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        style={styles.itemsPerPageSelect}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>
            </div>

            {/* Display loading spinner */}
            {isLoading && <Spinner />}

            {/* Display error message if any */}
            {error && <div style={styles.errorText}>{error}</div>}

            {/* Display "No results found" if not loading, no error, and no IPs */}
            {!isLoading && !error && allIPs.length === 0 && (
                <div style={styles.noResultsText}>No connected IPs found.</div>
            )}

            {/* Table Component */}
            {!isLoading && !error && allIPs.length > 0 && (
                <>
                    <Table
                        title={"Connected IPs"}
                        headers={headers}
                        data={ips} // Pass the current page's IPs
                        preffixColumn={null}
                        suffixColumn={"Actions"}
                        renderPrefix={null} // Assuming no prefix column, adjust if needed
                        renderSuffix={renderSuffix}
                        customRenderers={{ "relay_server_name": renderIP }}
                        excludeFields={['relay_server_id', 'status', 'health', 'open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}
                    />

                    {/* Pagination Controls */}
                    <div style={styles.paginationContainer}>
                        {/* Previous Button */}
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={styles.paginationButton(false, currentPage === 1)}
                            aria-label="Previous Page"
                        >
                            &laquo;
                        </button>

                        {/* Page Numbers and Ellipses */}
                        {getPageNumbers().map((item, index) => {
                            if (item === 'left-ellipsis' || item === 'right-ellipsis') {
                                return (
                                    <span key={index} style={styles.paginationEllipsis}>
                                        &hellip;
                                    </span>
                                );
                            }

                            return (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(item)}
                                    style={styles.paginationButton(currentPage === item, false)}
                                    aria-current={currentPage === item ? 'page' : undefined}
                                    aria-label={`Page ${item}`}
                                >
                                    {item}
                                </button>
                            );
                        })}

                        {/* Next Button */}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={styles.paginationButton(false, currentPage === totalPages)}
                            aria-label="Next Page"
                        >
                            &raquo;
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ConnectedIpsIPsTable;
