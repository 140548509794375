import React, { useEffect, useState } from 'react';
import Table from '../parents/Table';
import { Link } from 'react-router-dom';
import useCachedToken from '../../../useCachedToken'; // Import the custom hook for token
import config from '../../../config/env';  // Import the config
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const ControlCenterMailboxesTable = ({ reloadKey }) => {
  // Use the custom hook to get the cached token
  const token = useCachedToken();

  // API URL: Fetch all mailboxes sorted from latest to oldest
  const url = `${DJANGO_HOST}mailbox/?sort_by=created_at&sort_order=desc`;

  const headers = [
    { name: "Password", available: true },
    { name: "First Name", available: true },
    { name: "Last Name", available: true },
    { name: "Sent Emails", available: true },
    { name: "Reply-To", available: true }
  ];

  // State variables for managing mailbox data and pagination
  const [allMailboxes, setAllMailboxes] = useState([]); // Store all fetched mailboxes
  const [mailboxes, setMailboxes] = useState([]); // Mailboxes to display on the current page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 4; // Fixed at 4 items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch mailboxes from the API
  const fetchMailboxes = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}` // Include auth token
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Log the mailbox emails to verify order (optional)
      console.log("Fetched Mailboxes sorted by created_at:", data.map(mailbox => ({ email: mailbox.email, created_at: mailbox.created_at })));

      setAllMailboxes(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching mailboxes:', error);
      setError('Failed to load mailboxes. Please try again later.');
      setAllMailboxes([]); // Reset to empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch mailboxes when component mounts or when token or reloadKey changes
  useEffect(() => {
    if (token) {
      fetchMailboxes();
    }
  }, [url, token, reloadKey]);

  // Calculate totalPages whenever allMailboxes changes
  useEffect(() => {
    const total = Math.ceil(allMailboxes.length / itemsPerPage);
    setTotalPages(total || 1); // Ensure at least 1 page
    setCurrentPage(1); // Reset to first page when data changes
  }, [allMailboxes]);

  // Update mailboxes to display on the current page
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setMailboxes(allMailboxes.slice(indexOfFirstItem, indexOfLastItem));
  }, [allMailboxes, currentPage, itemsPerPage]);

  // Pagination Helper Function to generate page numbers with ellipses
  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= 2) {
      // If there are 2 or fewer pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Determine when to show ellipses and which page numbers to display
      if (currentPage > 2) {
        pages.push('left-ellipsis');
      }

      // Always show currentPage and its immediate neighbors
      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 1) {
        pages.push('right-ellipsis');
      }
    }

    return pages;
  };

  // Handle page change when a page number is clicked
  const handlePageChange = (pageNumber) => {
    if (pageNumber === 'left-ellipsis') {
      setCurrentPage(1); // Jump to first page
      return;
    }
    if (pageNumber === 'right-ellipsis') {
      setCurrentPage(totalPages); // Jump to last page
      return;
    }
    setCurrentPage(pageNumber);
  };

  // Style Objects for consistent styling
  const styles = {
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center', // Center the pagination controls
      alignItems: 'center',
      marginTop: '20px',
      gap: '5px',
    },
    paginationButton: (isActive, isDisabled) => ({
      padding: '8px 12px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: isActive ? '#17a2b8' : 'white',
      color: isActive ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      opacity: isDisabled ? 0.6 : 1,
    }),
    paginationEllipsis: {
      padding: '8px 12px',
    },
    seeMoreLink: {
      textDecoration: 'none',
      color: '#17a2b8',
      fontWeight: 'bold',
      padding: '10px',
    },
    errorText: {
      color: 'red',
      textAlign: 'center',
      marginTop: '10px',
    },
    noResultsText: {
      textAlign: 'center',
      marginTop: '20px',
      color: '#555',
    },
  };

  // Custom renderers for specific columns
  const renderMailboxLink = (cell, row) => {
    const mailboxId = row.id;
    return (
      <td className="py-3 px-6 whitespace-normal break-words">
        <Link to={`/navigate/mailbox/${mailboxId}/`}>
          {cell}
        </Link>
      </td>
    );
  };

  const renderStatus = (cell, row) => {
    const status = row.status || cell;
    return (
      <td className="py-3 px-6">
        {status === 'Active' ? 
          <span className="text-green-500">Active</span> : 
          <span className="text-red-500">Inactive</span>
        }
      </td>
    );
  };

  const renderSuffix = (row) => {  };

  const renderPrefix = (row) => {
    // Debugging: Log the mailbox and domain values
    console.log('Row data:', row);
    console.log('Email:', row.email);
    console.log('Domain:', row.domain_name);

    if (row.email && row.domain_name) {
        return (
            <td className="py-3 px-2">
                {`${row.email}@${row.domain_name}`} {/* Concatenate mailbox and domain */}
            </td>
        );
    } else {
        // Log a warning if email or domain is missing
        console.warn('Missing email or domain for row:', row);
        return <td className="py-3 px-2">N/A</td>;
    }
};

  return (
    <div>
      {/* Display loading spinner */}
      {isLoading && <Spinner />}

      {/* Display error message if any */}
      {error && <div style={styles.errorText}>{error}</div>}

      {/* Display "No results found" if not loading, no error, and no mailboxes */}
      {!isLoading && !error && allMailboxes.length === 0 && (
        <div style={styles.noResultsText}>No mailboxes found.</div>
      )}

      {/* Table Component */}
      {!isLoading && !error && allMailboxes.length > 0 && (
        <>
          <Table
            data={mailboxes} // Pass the current page's mailboxes
            title={"Mailboxes"}
            headers={headers}
            preffixColumn={"Mailbox"}
            renderPrefix={renderPrefix}
            renderSuffix={renderSuffix}
            customRenderers={{ 
              "Mailbox": renderMailboxLink,
              "Status": renderStatus
            }}
            excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints', 'health', 'email', 'domain_name']}
          />

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div style={styles.paginationContainer}>
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={styles.paginationButton(false, currentPage === 1)}
                aria-label="Previous Page"
              >
                &laquo;
              </button>

              {/* Page Numbers and Ellipses */}
              {getPageNumbers().map((item, index) => {
                if (item === 'left-ellipsis' || item === 'right-ellipsis') {
                  return (
                    <span key={index} style={styles.paginationEllipsis}>
                      &hellip;
                    </span>
                  );
                }

                return (
                  <button
                    key={index}
                    onClick={() => handlePageChange(item)}
                    style={styles.paginationButton(currentPage === item, false)}
                    aria-current={currentPage === item ? 'page' : undefined}
                    aria-label={`Page ${item}`}
                  >
                    {item}
                  </button>
                );
              })}

              {/* Next Button */}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={styles.paginationButton(false, currentPage === totalPages)}
                aria-label="Next Page"
              >
                &raquo;
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ControlCenterMailboxesTable;
